import { useState, useEffect } from "react";
import { Form, Formik, useFormikContext } from "formik";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useHistory } from "react-router";
import { RootState } from "store/store";
import * as Yup from "yup";
import FolderIco from "assets/images/column-folder.png";
import { ReactComponent as Arrow } from "../../../assets/icons/arrow.svg";
import { useTranslation } from "react-i18next";
// @ts-ignore
import Modal from "react-modal";
import SubmitButton from "components/common/buttons/submitButton/SubmitButton";
import TextField from "components/common/inputs/textInput/TextField";
import Map from "../Map";
import { ISelectOption } from "components/common/inputs/inputTypes";

import styled from "styled-components";

import NewSelectStatystics from "components/common/inputs/select/NewSelectStatystics";

import eventsServices from "services/eventsServices";

import useInfiniteScroll from "hooks/useInfiniteScroll";
import { PlayerProfileInterface } from "types/userTypes";

import logo from "../../../assets/images/logo.svg";
import { getAllAcademyPlayersQuery } from "services/transferServices";
import TextAreaField from "components/common/inputs/textArea/TextAreaField";
import SelectWithoutFilter from "components/common/inputs/select/SelectWithoutFilter";
import NewImageField from "components/common/inputs/imageInput/NewImageField";
import Button from "components/common/buttons/basicButton/Button";
import { ButtonVariant } from "components/common/buttons/buttonTypes";
import MaskedField from "components/common/inputs/maskedInput/MaskedField";
import { Mask } from "utils/constants/constants";
import TextInput from "components/common/inputs/textInput/TextInput";
import cyclicalEventServices from "services/cyclicalEventServices";
import favoriteLocationServices from "services/favoriteLocationServices";
import {
  GetOwnFolders,
  getFolderItemsByFolderId,
} from "services/folderServices";
import { validationMessage } from "utils/validation";
import BlockedDateTextInput from "components/common/inputs/textInput/BlockedDateTextInput";
import BackButton from "components/common/buttons/basicButton/BackButton";
import { utcToLocalDate } from "../detail/EventsDetail";

const SingleColumn = styled.div`
  width: 33%;
  display: flex;
  flex-direction: column;

  & > p {
    text-align: center;
  }

  & > * {
    width: 280px;
  }
  /* align-items: center; */
`;
interface IEditDigitalReleaseProps {
  event: any;
  isEdit: boolean;
  onSubmit: any;
  toggleAddMenuOpen?: any;
}

function MyComponent({ location, setLatitude, setLongitude }: any) {
  const { values, setFieldValue } = useFormikContext<any>();

  const getTest = async () => {
    try {
      if (location) {
        // setFieldValue("PostCode", location.postalCode);
        setFieldValue("City", location.city);
        setFieldValue("country", location.country);
        setLatitude(location?.position?.lat);
        setLongitude(location?.position?.lng);
        // if (location.street) {
        //   setFieldValue(
        //     "Address",
        //     `${location.street} ${location.streetNumber}`,
        //   );
        // } else {
        //   setFieldValue("Address", "");
        // }
      } else {
        // setFieldValue("PostCode", "");
        setFieldValue("City", "");
        setFieldValue("country", "");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (location) {
      getTest();
    } else {
      setFieldValue("City", "");
      setFieldValue("Address", "");
    }
  }, [location]);

  return <></>;
}

const EditDraftEventForm: React.FC<IEditDigitalReleaseProps> = ({
  event,
  isEdit,
  onSubmit,
  toggleAddMenuOpen,
}) => {
  const { t } = useTranslation();
  const permissions = useSelector((state: RootState) => state.userPermissions);
  const { replace } = useHistory();
  const [selectedType, setSelectedType] = useState<ISelectOption | null>(null);
  const [selectedVat, setSelectedVat] = useState<ISelectOption | null>(null);
  const [availableFrom, setAvailableFrom] = useState(
    utcToLocalDate(event?.OccurenceDate)?.slice(0, 10),
  );
  const [availableTo, setAvailableTo] = useState(
    utcToLocalDate(event?.EndDate)?.slice(0, 10),
  );

  const [base64, setBase64] = useState("");
  const [categories, setCategories] = useState([]);
  const [timeFrom, setTimeFrom] = useState(
    utcToLocalDate(event?.OccurenceDate)?.slice(11, 16),
  );
  const [timeTo, setTimeTo] = useState(
    utcToLocalDate(event?.EndDate)?.slice(11, 16),
  );
  const [visibility, setVisibility] =
    useState<{ value: number; label: string } | null>(null);
  const [visibilities, setVisibilities] = useState<
    { value: number; label: string }[]
  >([]);
  const [isAddressModalOpen, setIsAddressModalOpen] = useState(false);
  const [isMapModalOpen, setIsMapModalOpen] = useState(false);
  const [isLimitChecked, setLimitChecked] = useState(event?.Limit?.HasLimit);
  const [isCalendarChecked, setCalendarChecked] = useState(
    event?.AddToCalendar,
  );
  const [isPostChecked, setPostChecked] = useState(
    event?.Content || event?.Files?.length > 0 ? true : false,
  );
  const [favoriteLocationData, setFavoriteLocationData] = useState<any>(null);
  const handleCalendarCheckboxChange = () => {
    setCalendarChecked((prev: any) => !prev);
  };
  const [isGroupModalOpen, setIsGroupModalOpen] = useState(false);
  const handlePostCheckboxChange = () => {
    setPostChecked((prev) => !prev);
  };
  const [type, setType] =
    useState<{ value: number; label: string } | null>(null);
  const [types, setTypes] = useState<{ value: number; label: string }[]>([]);

  const [favLocation, setFavLocation] =
    useState<{ value: string; label: string } | null>(null);
  const [favLocations, setFavLocations] = useState<
    { value: number; label: string }[]
  >([]);

  const [level, setLevel] =
    useState<{ value: number; label: string } | null>(null);
  const [levels, setLevels] = useState<{ value: number; label: string }[]>([]);

  const [limit, setLimit] =
    useState<{ value: boolean; label: string } | null>(null);
  const [limits, setLimits] = useState<{ value: boolean; label: string }[]>([]);

  const [surface, setSurface] =
    useState<{ value: number; label: string } | null>(null);
  const [surfaces, setSurfaces] = useState<{ value: number; label: string }[]>(
    [],
  );
  const closeGroupModalOpen = () => {
    setIsGroupModalOpen(false);
  };
  const today = new Date().toISOString().split("T")[0];
  const [maxDate, setMaxDate] = useState("");

  useEffect(() => {
    if (availableFrom) {
      const maxEndDate = new Date(availableFrom);

      maxEndDate.setMonth(maxEndDate.getMonth() + 10);

      setMaxDate(maxEndDate.toISOString().split("T")[0]);
    }
  }, [availableFrom]);
  const [surfaceDimesion, setSurfaceDimesion] =
    useState<{ value: number; label: string } | null>(null);
  const [surfaceDimesions, setSurfaceDimesions] = useState<
    { value: number; label: string }[]
  >([]);
  const [folderTrees, setFolderTrees] = useState([]);
  const [localizationType, setLocalizationType] = useState(
    event?.Localization ? 3 : 1,
  );
  const [playersInFolder, setPlayersInFolder] = useState([]);
  const [playerProfiles, setPlayerProfiles] = useState([]);
  const [allFields, setAllFields] = useState([]);
  const [daysBeforeEvent, setDaysBeforeEvent] =
    useState<{ value: number; label: string } | null>(null);
  const [allDaysBeforeEvent, setAllDaysBeforeEvent] = useState<
    { value: number; label: string }[]
  >([]);
  const [repetitationInterval, setRepetitationInterval] =
    useState<{ value: number; label: string } | null>(null);
  const [repetitationIntervals, setRepetitationIntervals] = useState<
    { value: number; label: string }[]
  >([]);
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  const [location, setLocation] = useState(null);
  const [academyFolder, setAcademyFolder] =
    useState<{ value: number; label: string } | null>(null);
  const [academyFolders, setAcademyFolders] = useState<
    { value: number; label: string }[]
  >([]);

  const closeMapModalOpen = () => {
    setIsMapModalOpen(false);
  };

  const getAllRepetitationIntervals = async () => {
    try {
      setRepetitationIntervals([
        {
          label: "Każdy poniedziałek",
          value: 1,
        },
        {
          label: "Każdy wtorek",
          value: 2,
        },
        {
          label: "Każda środa",
          value: 3,
        },
        {
          label: "Każdy czwartek",
          value: 4,
        },
        {
          label: "Każdy piątek",
          value: 5,
        },
        {
          label: "Każda sobota",
          value: 6,
        },
        {
          label: "Każda niedziela",
          value: 7,
        },
        {
          label: "Codziennie",
          value: 8,
        },
        {
          label: "Co miesiąc",
          value: 9,
        },
      ]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllRepetitationIntervals();
  }, []);

  const handleLimitCheckboxChange = () => {
    setLimitChecked((prev: any) => !prev);
  };
  // const [selectedCategory, setSelectedCategory] = useState({
  //   // @ts-ignore
  //   label: event.Category.Name,
  //   // @ts-ignore
  //   value: event.Category.CategoryId,
  // });

  const deleteFolder = (id: string) => {
    setAllFolders((prevFolders: any) =>
      prevFolders.filter((folder: any) => folder.Id !== id),
    );
  };
  const [ownFolders, setOwnFolders] = useState<any>([]);
  const deletePlayer = (playerId: string) => {
    // setAllPlayers((prevPlayers: any) =>
    //   prevPlayers.filter((player: any) => player.Id !== id),
    // );
    const updatedInvitations = [...invitations];
    const updatedRevokeInvitations = [...revokeInvitations];
    const playerIndexInInvitations = updatedInvitations.findIndex(
      (inv: any) => inv.ProfileId === playerId,
    );

    if (playerIndexInInvitations !== -1) {
      const removedPlayer: any = updatedInvitations.splice(
        playerIndexInInvitations,
        1,
      )[0];
      // @ts-ignore
      updatedRevokeInvitations.push(removedPlayer);

      const updatedPlayers = allPlayers.filter(
        (player: any) => player.Id !== playerId,
      );
      setAllPlayers(updatedPlayers);
    } else {
      const removedPlayer: any = allPlayers.find(
        (player: any) => player.Id === playerId,
      );

      if (removedPlayer) {
        // @ts-ignore
        updatedRevokeInvitations.push(removedPlayer);
        const updatedPlayers = allPlayers.filter(
          (player: any) => player.Id !== playerId,
        );
        setAllPlayers(updatedPlayers);
      }
    }

    setInvitations(updatedInvitations);
    setRevokeInvitations(updatedRevokeInvitations);
  };
  const handleLocationChange = (newLocation: any) => {
    setLocation(newLocation);
  };

  const getAllAcademyFolders = async () => {
    try {
      const resp = await GetOwnFolders();
      console.log("respFOLDEROWN", resp.Data);

      const mappedFolders = resp.Data.map((folder: any) => ({
        label: folder.Name,
        value: folder.FolderId,
      }));

      setAcademyFolders(mappedFolders);
      setOwnFolders(resp.Data);

      const buildFolderTree = (folders: any, parentId: any) => {
        const subFolders = folders.filter(
          (folder: any) => folder.ParentFolderId === parentId,
        );
        return subFolders.map((folder: any) => ({
          ...folder,
          subFolder: buildFolderTree(folders, folder.FolderId),
        }));
      };

      const topLevelFolders = resp.Data.filter(
        (folder: any) =>
          folder.ParentFolderId === "00000000-0000-0000-0000-000000000000",
      );
      const folderTrees = topLevelFolders.map((folder: any) => ({
        ...folder,
        subFolder: buildFolderTree(resp.Data, folder.FolderId),
      }));

      console.log("folderTrees", folderTrees);
      setFolderTrees(folderTrees);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllAcademyFolders();
  }, []);

  const [selectedFolders, setSelectedFolders] = useState<any>([]);
  const [selectedTopLevelFolderIds, setSelectedTopLevelFolderIds] =
    useState<any>([]);

  const findTopLevelFolderId = (folder: any, currentFolderId: any): string => {
    if (!folder.ParentFolderId) {
      return folder.FolderId;
    }

    const parentFolder = findFolderById(folder, folder.ParentFolderId);
    return findTopLevelFolderId(parentFolder, currentFolderId);
  };

  const findFolderById = (folder: any, folderId: any) => {
    if (folder.FolderId === folderId) {
      return folder;
    }
    if (folder.subFolder) {
      for (let i = 0; i < folder.subFolder.length; i++) {
        const foundFolder: any = findFolderById(folder.subFolder[i], folderId);
        if (foundFolder) {
          return foundFolder;
        }
      }
    }
    return null;
  };

  const handleSubmit = async (values: any) => {
    if (!values?.Name) return toast.error("Uzupełnij nazwe!");
    if (!values?.Content && isPostChecked)
      return toast.error("Uzupełnij opis!");
    if (!type) return toast.error("Wybierz typ zajęcia!");
    if (!level) return toast.error("Wybierz poziom zajęcia!");
    if (!availableFrom) return toast.error("Wybierz daty!");
    if (!timeFrom) return toast.error("Wybierz godziny!");
    if (!timeTo) return toast.error("Wybierz godziny!");
    if (!availableTo) return toast.error("Wybierz daty!");
    if (!repetitationInterval) return toast.error("Wybierz powtarzanie!");
    if (!surface) return toast.error("Wybierz nawierzchnie!");
    if (!surfaceDimesion) return toast.error("Wybierz opis nawierzchni!");
    if (isPostChecked && !base64 && event?.Files.length === 0)
      return toast.error("Dodaj zdjęcie!");

    if (!visibility) return toast.error("Wybierz widoczność zajęcia!");
    if (timeFrom && timeTo) {
      if (timeFrom > timeTo) return toast.error("Błędne godziny!");
    }
    if (isPostChecked && !values?.Content) {
      return toast.error("Uzupełnij opis!");
    }

    delete values.Visibility;
    delete values.Groups;
    console.log("selectedFolders", selectedFolders);
    await onSubmit(
      values,
      `${availableFrom}T${timeFrom}:00`,
      `${availableTo}T${timeTo}:00`,
      visibility?.value,
      level?.value,
      type?.value,
      surface?.value,
      surfaceDimesion?.value,
      isLimitChecked,
      visibility?.value === 2 ? playersInFolder : allPlayers,
      0,
      repetitationInterval?.value,
      localizationType === 3
        ? favoriteLocationData?.Latitude
          ? favoriteLocationData.Latitude
          : event.Latitude
        : latitude,
      localizationType === 3
        ? favoriteLocationData?.Longitude
          ? favoriteLocationData.Longitude
          : event.Longitude
        : longitude,
      isCalendarChecked,
      isCalendarChecked ? daysBeforeEvent?.value : 0,
      isPostChecked,
      false,
      event?.EventId,
      event?.Files,
      selectedFolders,
    );
  };

  // const handleSubmit = async (values: any) => {
  //   // if (!base64) return toast.error("Dodaj zdjęcie!");
  //   // if (!selectedVat) return toast.error("Wybierz Vat!");
  //   // if (!selectedType) return toast.error("Wybierz typ produktu!");

  //   const newValues = {
  //     Name: values.Name,
  //     Content: values.Content,
  //     Limit: +values.Limit.Limit,
  //   };

  //   // const imageUrl = await sellerServices.uploadFile(base64);
  //   await onSubmit(
  //     newValues,
  //     event?.EventId,
  //     visibility?.value,
  //     level?.value,
  //     type?.value,
  //     surface?.value,
  //     surfaceDimesion?.value,
  //     limit?.value,
  //     invitations,
  //     revokeInvitations,
  //   );
  // };
  const getAllSubFolderIds = (folder: any) => {
    const subFolderIds = folder.subFolder
      ? folder.subFolder.map((subFolder: any) => subFolder.FolderId)
      : [];
    folder.subFolder &&
      folder.subFolder.forEach((subFolder: any) => {
        subFolderIds.push(...getAllSubFolderIds(subFolder));
      });
    return subFolderIds;
  };
  const handleFolderClick = (folderId: any, folder: any) => {
    const folderIndex = selectedFolders.indexOf(folderId);
    let newSelectedFolders;
    const topLevelFolderId = findTopLevelFolderId(folderId, folder);

    if (!selectedTopLevelFolderIds.includes(topLevelFolderId)) {
      setSelectedTopLevelFolderIds([
        ...selectedTopLevelFolderIds,
        topLevelFolderId,
      ]);
    } else {
      setSelectedTopLevelFolderIds(
        selectedTopLevelFolderIds.filter((id: any) => id !== topLevelFolderId),
      );
    }
    if (folderIndex === -1) {
      newSelectedFolders = [
        ...selectedFolders,
        folderId,
        ...getAllSubFolderIds(folder),
      ];
    } else {
      const subFolderIds = getAllSubFolderIds(folder);
      newSelectedFolders = selectedFolders.filter(
        (id: any) => ![...subFolderIds, folderId].includes(id),
      );

      const allParentFoldersSelected = selectedFolders.some((parentId: any) => {
        const parentFolder = findFolderById(parentId, folder.ParentFolderId);
        if (parentFolder) {
          const parentSubFolderIds = getAllSubFolderIds(parentFolder);
          return parentSubFolderIds.every((subFolderId: any) =>
            selectedFolders.includes(subFolderId),
          );
        }
        return false;
      });

      if (!allParentFoldersSelected) {
        newSelectedFolders = newSelectedFolders.filter(
          (id: any) => !folder.ParentFolderId || id !== folder.ParentFolderId,
        );
      }
    }

    setSelectedFolders(newSelectedFolders);
  };
  const productTypeSwitch = (value: any) => {
    switch (value) {
      case 1:
        return "Fizyczny";
      case 2:
        // code block
        return "Elektroniczny";
      default:
        return value;
    }
  };

  const isFolderSelected = (folderId: any) =>
    selectedFolders.includes(folderId);

  const renderFolder = (folder: any, depth = 0) => {
    const folderColor = isFolderSelected(folder.FolderId)
      ? "rgba(0,145,255,0.21)"
      : "rgba(163,168,157,0.11)";

    const folderFontColor = isFolderSelected(folder.FolderId)
      ? "white"
      : "rgb(112,112,112)";

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "5px",
          marginLeft: `${depth * 20}px`,
        }}
      >
        <div
          style={{
            background: folderColor,
            height: "48px",
            display: "flex",
            gap: "18px",
            alignItems: "center",
            padding: "0px 16px",
            fontSize: "15px",
            color: folderFontColor,
            cursor: "pointer",
          }}
          onClick={() => handleFolderClick(folder.FolderId, folder)}
        >
          <img src={FolderIco} alt="folder" />
          <p id={folder.FolderId}>{folder.Name}</p>
        </div>
        {folder.subFolder &&
          folder.subFolder.map((subFolder: any) =>
            renderFolder(subFolder, depth + 1),
          )}
      </div>
    );
  };

  const [productType, setProductType] = useState<{
    value: number;
    label: string;
  } | null>({
    // @ts-ignore
    label: productTypeSwitch(event.Type),
    // @ts-ignore
    value: event.Type,
  });
  const [productTypes, setProductTypes] = useState<
    { value: number; label: string }[]
  >([]);

  const getFolderItemsByFolderIdFn = async (id: any) => {
    const resp = await getFolderItemsByFolderId(id);
    console.log("ZAWODNICYWFOLDERZE", resp.Data);

    if (resp.Data && resp.Data.length > 0) {
      const mappedPlayers = resp.Data.map((player: any) => ({
        Id: player.PlayerId,
        Type: 1,
      }));

      setPlayersInFolder(mappedPlayers);
    } else {
      setPlayersInFolder([]);
    }
  };

  useEffect(() => {
    setPlayersInFolder([]);
    if (academyFolder?.value) {
      getFolderItemsByFolderIdFn(academyFolder?.value);
    }
  }, [academyFolder?.value]);

  const getAllVisibility = async () => {
    try {
      setVisibilities([
        {
          label: "Wszyscy",
          value: 0,
        },
        {
          label: "Tylko zaproszeni",
          value: 1,
        },
        {
          label: "Grupa",
          value: 2,
        },
      ]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllVisibility();
  }, []);

  useEffect(() => {
    setPlayersInFolder([]);
    if (academyFolder?.value) {
      getFolderItemsByFolderIdFn(academyFolder?.value);
    }
  }, [academyFolder?.value]);

  const getAllFavLocations = async () => {
    try {
      const resp: any =
        await favoriteLocationServices.getAllFavoriteLocations();
      console.log("alllocations", resp);
      const mappedLocations: any = resp.map((location: any) => ({
        label: location.Name,
        value: location.FavoriteLocationId,
      }));

      setFavLocations(mappedLocations);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getAllFavLocations();
  }, []);

  const getAllTypes = async () => {
    try {
      setTypes([
        {
          label: "Trening",
          value: 0,
        },
        {
          label: "Trening aerobowy",
          value: 1,
        },
        {
          label: "Trening personalny",
          value: 2,
        },
        {
          label: "Badania okresowe",
          value: 3,
        },
        {
          label: "Mecz piłkarski",
          value: 4,
        },
      ]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllTypes();
  }, []);
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      maxWidth: "80%",
      maxHeight: "80%",
      width: "80%",
      height: "80%",
      background: "#1a2530",
      margin: 0,
      padding: 0,
      border: "2px solid lightgray",
      borderTop: "30px solid lightgray",
    },
  };

  const customGroupStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      maxWidth: "80%",
      maxHeight: "80%",
      width: "80%",
      height: "80%",
      background: "#1a2530",
      margin: 0,
      padding: 0,
      border: "2px solid lightgray",
      borderTop: "30px solid lightgray",
    },
  };
  const [isFocused, toggleFocused] = useState(false);
  const [receiver, setReceiver] = useState("");
  const [receiverId, setReceiverId] = useState("");
  const [playerImage, setPlayerImage] = useState("");
  const [playerType, setPlayerType] = useState("");
  const [allPlayers, setAllPlayers] = useState<any>([]);
  const [allFolders, setAllFolders] = useState<any>([]);
  const [invitations, setInvitations] = useState<any>([]);
  const [revokeInvitations, setRevokeInvitations] = useState([]);
  const onBlur = (e: any) => {
    if (!e.currentTarget.contains(e.relatedTarget)) return toggleFocused(false);
    return toggleFocused(true);
  };

  useEffect(() => {
    if (academyFolder?.value) {
      const idAlreadyExists = allFolders.some(
        (folder: any) => folder.Id === academyFolder?.value,
      );

      if (!idAlreadyExists) {
        setAllFolders((prev: any) => [
          ...prev,
          {
            Name: academyFolder?.label,
            Id: academyFolder?.value,
          },
        ]);
        setAcademyFolder(null);
      } else {
        toast.error("Grupa została już dodana!");
        setAcademyFolder(null);
      }

      // setAcademyFolders((prevFolders: any) =>
      //   prevFolders.filter(
      //     (folder: any) => folder.value !== academyFolder?.value,
      //   ),
      // );
    }
  }, [academyFolder]);

  useEffect(() => {
    if (visibility?.value !== 1) {
      setAllPlayers([]);
    }
  }, [visibility]);

  useEffect(() => {
    if (visibility?.value !== 2) {
      setAllFolders([]);
    }
  }, [visibility]);

  useEffect(() => {
    if (event?.Invitations?.length > 0) {
      event.Invitations.map((inv: any) => {
        setAllPlayers((prev: any) => [
          ...prev,
          {
            Name: inv.Name,
            Id: inv.ProfileId,
            Type: inv.ProfileType,
            Image: inv.PhotoFilePath,
          },
        ]);
      });

      setReceiverId("");
      setReceiver("");
      setPlayerType("");
      setPlayerImage("");
    }
  }, []);

  useEffect(() => {
    if (event?.Groups?.length > 0) {
      event.Groups.map((group: any) => {
        setAllFolders((prev: any) => [
          ...prev,
          {
            Name: group.GroupName,
            Id: group.GroupId,
          },
        ]);
      });
    }
  }, []);

  useEffect(() => {
    if (receiverId) {
      setAllPlayers((prev: any) => [
        ...prev,
        {
          Name: receiver,
          Id: receiverId,
          Type: playerType,
          Image: playerImage,
        },
      ]);
      setInvitations((prev: any) => [
        ...prev,
        {
          Name: receiver,
          Id: receiverId,
          Type: playerType,
          Image: playerImage,
        },
      ]);
      setReceiverId("");
      setReceiver("");
      setPlayerType("");
      setPlayerImage("");
    }
  }, [receiverId]);

  const handleReceiverChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setReceiver(e.target.value);

  const handleReceiverIdChange = (value: string) => setReceiverId(value);

  const handleOnFocusChange = (value: boolean) => toggleFocused(value);

  const getAllLimits = async () => {
    try {
      setLimits([
        {
          label: "Tak",
          value: true,
        },
        {
          label: "Nie",
          value: false,
        },
      ]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllLimits();
  }, []);

  const getFavoriteLocationFn = async (id: string) => {
    const resp: any = await favoriteLocationServices.getFavoriteLocation(id);
    console.log("favlocation", resp);
    setFavoriteLocationData(resp);
  };

  useEffect(() => {
    if (favLocation?.value) {
      setLocalizationType(3);
      getFavoriteLocationFn(favLocation?.value);
    }
  }, [favLocation]);

  const getAllLevels = async () => {
    try {
      setLevels([
        {
          label: "Klub",
          value: 0,
        },
        {
          label: "Reprezentacja",
          value: 1,
        },
        {
          label: "Własne",
          value: 2,
        },
      ]);
    } catch (error) {
      console.log(error);
    }
  };

  const getAllDaysBeforeEvent = async () => {
    try {
      setAllDaysBeforeEvent([
        {
          label: "7 dni",
          value: 0,
        },
        {
          label: "14 dni",
          value: 1,
        },
        {
          label: "30 dni",
          value: 2,
        },
      ]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllDaysBeforeEvent();
  }, []);

  useEffect(() => {
    getAllLevels();
  }, []);

  const addFavoriteLocationFn = async (name: string, note: string) => {
    if (!type?.label) return toast.error("Wybierz typ zadania!");
    if (!surface?.label) return toast.error("Wybierz nawierzchnie!");
    if (!surfaceDimesion?.label)
      return toast.error("Wybierz opis nawierzchni!");
    if (!latitude && !longitude)
      return toast.error("Wybierz lokalizacje z mapy!");
    if (!name) return toast.error("Wpisz nazwe miejsca!");

    await favoriteLocationServices.addFavoriteLocation(
      name,
      note,
      latitude,
      longitude,
      surface?.value,
      surfaceDimesion?.value,
    );
    toast.success("Dodano ulubioną lokalizacje!");
  };

  const getEventFields = async (type: number) => {
    try {
      // const resp: any = await eventsServices.getEventFields(type);
      const resp: any = await eventsServices.getSurfacesWithDimensions();
      console.log("DIMENSIONS>>>", resp);
      const surfacesArray: any = [];
      const surfaceDimensionsArray: any = [];

      resp?.Surface.map((single: any) => {
        return surfacesArray.push({
          value: single.Id,
          label: single.Name,
        });
      });

      resp?.SurfaceDimensions.map((single: any) => {
        return surfaceDimensionsArray.push({
          value: single.Id,
          label: `${single.SurfaceLengthMeters} x ${single.SurfaceWidthMeters}`,
        });
      });

      setSurfaces(surfacesArray);
      setSurfaceDimesions(surfaceDimensionsArray);
      // console.log("fieldsy", resp);
      // setAllFields(resp);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (type?.label) {
      getEventFields(type.value);
    }
  }, [type]);

  useEffect(() => {
    if (surface?.label && allFields.length > 0) {
      const categoriesArray: any = [];
      const foundObj: any = allFields.find(
        (obj: any) => obj.FieldId === surface.value,
      );
      if (foundObj) {
        foundObj.Values.map((single: any) => {
          return categoriesArray.push({
            value: single.ValueId,
            label: single.ValueName,
          });
        });

        setSurfaceDimesions(categoriesArray);
      }
    }
  }, [surface]);

  // const digitalReleaseValidation = Yup.object().shape({
  //   Name: Yup.string().required(validationMessage.isRequired),
  //   // Content: Yup.string().when("IsPostChecked", {
  //   //   is: true,
  //   //   then: Yup.string().required(validationMessage.isRequired),
  //   //   otherwise: Yup.string(),
  //   // }),
  //   // Address: Yup.string().required(validationMessage.isRequired),
  //   // PostCode: Yup.string().required(validationMessage.isRequired),
  //   // City: Yup.string().required(validationMessage.isRequired),
  // });
  useEffect(() => {
    if (academyFolder?.value) {
      const idAlreadyExists = allFolders.some(
        (folder: any) => folder.Id === academyFolder?.value,
      );

      if (!idAlreadyExists) {
        setAllFolders((prev: any) => [
          ...prev,
          {
            Name: academyFolder?.label,
            Id: academyFolder?.value,
          },
        ]);
        setAcademyFolder(null);
      } else {
        toast.error("Grupa została już dodana!");
        setAcademyFolder(null);
      }

      // setAcademyFolders((prevFolders: any) =>
      //   prevFolders.filter(
      //     (folder: any) => folder.value !== academyFolder?.value,
      //   ),
      // );
    }
  }, [academyFolder]);

  useEffect(() => {
    getAllAcademyPlayersQuery(receiver)
      .then((data) => {
        setPlayerProfiles(data.Data.Items);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [receiver]);
  console.log("draftedycja", event);
  return (
    <Formik
      initialValues={event}
      onSubmit={handleSubmit}
      validateOnMount
      // validationSchema={digitalReleaseValidation}
    >
      {({ errors, isSubmitting, values, initialValues, setFieldValue }) => (
        <Form
          className="flex"
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            width: "100%",
          }}
        >
          <Container
            style={{
              display: "flex",
              width: "100%",
              height: "calc(100% - 70px)",
              overflowY: "auto",
            }}
          >
            <Modal
              isOpen={isMapModalOpen}
              onRequestClose={closeMapModalOpen}
              style={customStyles}
            >
              <Map onLocationChange={handleLocationChange} />
            </Modal>
            <Modal
              isOpen={isGroupModalOpen}
              onRequestClose={closeGroupModalOpen}
              style={customGroupStyles}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    height: "50px",
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    padding: "0 10px",
                  }}
                >
                  <Button
                    variant={ButtonVariant.Save}
                    onClick={() => setIsGroupModalOpen(false)}
                  >
                    Zapisz
                  </Button>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "40px",
                    padding: "10px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "20px",
                    }}
                  >
                    {folderTrees.length > 0 &&
                      folderTrees.map((foldertree: any) =>
                        renderFolder(foldertree),
                      )}
                  </div>
                </div>
              </div>
            </Modal>

            <MyComponent
              location={location}
              setLatitude={setLatitude}
              setLongitude={setLongitude}
            />

            <NameBox>
              <BackButton
                className="w-11 h-11 z-10 hover:opacity-100 shadow p-0 flex-shrink-0"
                variant={ButtonVariant.Submit}
                style={{ width: "48px", height: "48px" }}
                onClick={() => toggleAddMenuOpen(false)}
              >
                <i>{<Arrow className="fill-current w-7" />}</i>
              </BackButton>

              <TextField
                name="Name"
                label={"Nazwa"}
                isError={false}
                style={{
                  paddingBottom: "9px",
                  width: "calc(100% - 48px - 25px)",
                  textAlign: "left",
                }}
              />
            </NameBox>

            <ColumnsContainer>
              <SingleColumn
                style={{ alignItems: "center", paddingLeft: "15px" }}
              >
                <p>DANE PODSTAWOWE</p>

                <NewSelectStatystics
                  name="ActivityType"
                  items={types}
                  label="Typ zadania"
                  style={{ width: "280px", paddingBottom: "9px" }}
                  // @ts-ignore
                  selectedItem={type}
                  setSelectedItem={setType}
                  defaultValue={event.Type}
                />

                {type?.label && type?.value === 4 && (
                  <NewSelectStatystics
                    name="Field"
                    items={surfaces}
                    label="Nawierzchnia"
                    style={{ width: "280px", paddingBottom: "9px" }}
                    // @ts-ignore
                    selectedItem={surface}
                    setSelectedItem={setSurface}
                    defaultValue={event?.Surface && event?.Surface?.SurfaceId}
                  />
                )}

                {surface?.label && type?.value === 4 && (
                  <NewSelectStatystics
                    name="FieldValue"
                    items={surfaceDimesions}
                    label="Rozmiar nawierzchni"
                    style={{ width: "280px", paddingBottom: "9px" }}
                    selectedItem={surfaceDimesion}
                    setSelectedItem={setSurfaceDimesion}
                    defaultValue={
                      event?.Surface && event?.Surface?.SurfaceDimensionsId
                    }
                  />
                  // dsadsa
                )}
                <NewSelectStatystics
                  name="ActivityLevel"
                  items={levels}
                  label="Poziom"
                  style={{ width: "280px", paddingBottom: "9px" }}
                  // @ts-ignore
                  selectedItem={level}
                  setSelectedItem={setLevel}
                  defaultValue={event.Level}
                />

                <div
                  style={{
                    width: "280px",
                    paddingBottom: "9px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "9px",
                      alignItems: "center",
                    }}
                  >
                    <p
                      style={{
                        textTransform: "uppercase",
                        fontSize: "15px",
                        fontWeight: 400,
                      }}
                    >
                      MIEJSCE
                    </p>
                    {localizationType === 2 && (
                      <p
                        onClick={() => setLocalizationType(1)}
                        style={{
                          color: "#0091FF",
                          fontSize: "11px",
                          cursor: "pointer",
                        }}
                      >
                        Cofnij
                      </p>
                    )}
                  </div>

                  {localizationType === 1 && (
                    <div
                      onClick={() => setLocalizationType(2)}
                      style={{
                        display: "flex",
                        gap: "9px",
                        cursor: "pointer",
                        alignItems: "center",
                        paddingRight: "5px",
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                      >
                        <path
                          id="Icon_material-add-box"
                          data-name="Icon material-add-box"
                          d="M15.167,4.5H5.833A1.333,1.333,0,0,0,4.5,5.833v9.333A1.333,1.333,0,0,0,5.833,16.5h9.333A1.337,1.337,0,0,0,16.5,15.167V5.833A1.337,1.337,0,0,0,15.167,4.5Zm-1.333,6.667H11.167v2.667H9.833V11.167H7.167V9.833H9.833V7.167h1.333V9.833h2.667Z"
                          transform="translate(-4.5 -4.5)"
                          fill="#0091ff"
                        />
                      </svg>

                      <p style={{ color: "#0091FF", fontSize: "11px" }}>
                        DODAJ
                      </p>
                    </div>
                  )}

                  {localizationType === 2 && (
                    <div
                      onClick={() => setIsMapModalOpen(true)}
                      style={{
                        display: "flex",
                        gap: "9px",
                        cursor: "pointer",
                        alignItems: "center",
                        paddingRight: "5px",
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="16"
                        viewBox="0 0 18 16"
                      >
                        <path
                          id="Icon_awesome-map-marked-alt"
                          data-name="Icon awesome-map-marked-alt"
                          d="M9,0A3.937,3.937,0,0,0,5.063,3.938c0,1.758,2.573,4.963,3.559,6.126a.493.493,0,0,0,.756,0C10.364,8.9,12.938,5.7,12.938,3.938A3.937,3.937,0,0,0,9,0ZM9,5.25a1.313,1.313,0,1,1,1.313-1.312A1.312,1.312,0,0,1,9,5.25ZM.629,6.748A1,1,0,0,0,0,7.677V15.5a.5.5,0,0,0,.686.464L5,14V6.716a9.464,9.464,0,0,1-.664-1.451ZM9,11.24a1.493,1.493,0,0,1-1.141-.53C7.245,9.985,6.591,9.159,6,8.312V14l6,2V8.313c-.591.847-1.244,1.672-1.859,2.4A1.494,1.494,0,0,1,9,11.24Zm8.314-6.2L13,7v9l4.371-1.748A1,1,0,0,0,18,13.323V5.5A.5.5,0,0,0,17.314,5.036Z"
                          fill="#0091ff"
                        />
                      </svg>

                      <p style={{ color: "#0091FF", fontSize: "11px" }}>Mapa</p>
                    </div>
                  )}

                  {localizationType === 3 && (
                    <div
                      onClick={() => setLocalizationType(1)}
                      style={{
                        display: "flex",
                        gap: "9px",
                        cursor: "pointer",
                        alignItems: "center",
                        paddingRight: "5px",
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="12.857"
                        viewBox="0 0 10 12.857"
                      >
                        <path
                          id="Icon_material-delete"
                          data-name="Icon material-delete"
                          d="M8.214,15.929a1.433,1.433,0,0,0,1.429,1.429h5.714a1.433,1.433,0,0,0,1.429-1.429V7.357H8.214ZM17.5,5.214H15L14.286,4.5H10.714L10,5.214H7.5V6.643h10Z"
                          transform="translate(-7.5 -4.5)"
                          fill="#dc2121"
                        />
                      </svg>

                      <p style={{ color: "#DC2121", fontSize: "11px" }}>USUŃ</p>
                    </div>
                  )}
                </div>

                {localizationType === 1 && (
                  <>
                    <NewSelectStatystics
                      name="FavLocation"
                      items={favLocations}
                      label="Lokalizacja"
                      style={{ width: "280px", paddingBottom: "9px" }}
                      // @ts-ignore
                      selectedItem={favLocation}
                      setSelectedItem={setFavLocation}
                    />
                  </>
                )}

                {localizationType === 3 && (
                  <>
                    <NewSelectStatystics
                      name="FavLocation"
                      items={favLocations}
                      label="Lokalizacja"
                      style={{ width: "280px", paddingBottom: "9px" }}
                      // @ts-ignore
                      selectedItem={favLocation}
                      setSelectedItem={setFavLocation}
                      defaultValue={favLocation?.value}
                    />
                    <div
                      style={{
                        paddingBottom: "19px",
                        color: "#0091FF",
                        fontSize: "15px",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      {(favoriteLocationData || event?.Localization) && (
                        <>
                          <p>
                            {favoriteLocationData?.Address
                              ? favoriteLocationData?.Address?.split(",")[0]
                              : event?.Localization.split(",")[0]}
                          </p>
                          <p>
                            {favoriteLocationData?.Address
                              ? favoriteLocationData?.Address?.split(",")[1]
                              : event?.Localization.split(",")[1]}
                          </p>
                        </>
                      )}
                    </div>
                  </>
                )}

                {localizationType === 2 && (
                  <>
                    <TextField
                      name="LocationName"
                      label={"Nazwa miejsca"}
                      placeholder=""
                      isError={false}
                      style={{ paddingBottom: "9px" }}
                    />
                    <TextField
                      name="LocationNote"
                      label={"Opis miejsca"}
                      placeholder=""
                      isError={false}
                      style={{ paddingBottom: "9px" }}
                    />
                    <TextField
                      name="country"
                      label={"Kraj"}
                      disabled
                      placeholder="-"
                      isError={false}
                      style={{ paddingBottom: "9px" }}
                    />
                    <TextField
                      name="City"
                      label={"Miasto"}
                      isError={false}
                      placeholder="-"
                      disabled
                      style={{ paddingBottom: "9px" }}
                    />
                    <Button
                      onClick={() => {
                        addFavoriteLocationFn(
                          values.LocationName,
                          values.LocationNote,
                        );
                      }}
                      className="px-24 xl:order-2"
                      variant={ButtonVariant.Submit}
                      style={{
                        padding: "15px 41.4064px",
                        background: "rgb(0, 160, 255)",
                        width: "280px",
                        fontSize: "15px",
                        fontWeight: 400,
                        order: 0,
                        marginBottom: "9px",
                      }}
                    >
                      Dodaj do ulubionych
                    </Button>
                  </>
                )}

                {/* <MaskedField
              mask={Mask.Postcode}
              name="PostCode"
              label="Kod pocztowy"
              placeholder="Uzupełnij"
              autoComplete="off"
            />
            <TextField isError={false} name="Address" label={"Adres"} />
            <TextField isError={false} name="City" label={"Miasto"} /> */}
                {/* <NewSelectStatystics
              name="CategoryId"
              style={{ width: "280px", paddingBottom:"9px" }}
              items={categories}
              label="Kategoria"
              // @ts-ignore
              selectedItem={selectedCategory}
              // @ts-ignore
              setSelectedItem={setSelectedCategory}
              // @ts-ignore
              defaultValue={event.Category.CategoryId}
            /> */}

                {/* {selectedType && (
              <NewSelectStatystics
                name="CategoryId"
                items={categories}
                label="Kategoria"
                // @ts-ignore
                selectedItem={selectedCategory}
                // @ts-ignore
                setSelectedItem={setSelectedCategory}
              />
            )} */}
                <p>CZAS</p>

                <BlockedDateTextInput
                  isError={false}
                  style={{
                    paddingBottom: "9px",

                    flex: "unset",
                  }}
                  name="OccurenceDate"
                  label={"Data"}
                  type="date"
                  min={today}
                  value={availableFrom}
                  onChange={(e) => setAvailableFrom(e.target.value)}
                />
                <div
                  style={{
                    width: "280px",
                    paddingBottom: "9px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <p
                    style={{
                      textTransform: "uppercase",
                      fontSize: "15px",
                      fontWeight: 400,
                      width: "140px",
                    }}
                  >
                    OD
                  </p>
                  <p
                    style={{
                      textTransform: "uppercase",
                      fontSize: "15px",
                      fontWeight: 400,
                      width: "140px",
                    }}
                  >
                    DO
                  </p>
                </div>
                <DateBox>
                  <TextInput
                    isError={false}
                    style={{ paddingBottom: "9px", flex: "unset" }}
                    name="timeFrom"
                    label={"Godzina"}
                    type="time"
                    value={timeFrom}
                    onChange={(e) => setTimeFrom(e.target.value)}
                  />
                  <TextInput
                    isError={false}
                    style={{ paddingBottom: "9px" }}
                    name="timeTo"
                    label={"Godzina"}
                    type="time"
                    value={timeTo}
                    onChange={(e) => setTimeTo(e.target.value)}
                  />
                </DateBox>
                <p style={{ marginTop: "5px" }}>DATA KOŃCA OKRESU</p>
                <BlockedDateTextInput
                  isError={false}
                  style={{ paddingBottom: "9px" }}
                  name="EndDate"
                  label={"Data"}
                  min={today}
                  type="date"
                  value={availableTo}
                  max={maxDate}
                  onChange={(e) => setAvailableTo(e.target.value)}
                />
                <SelectWithoutFilter
                  name="RepetitationInterval"
                  items={repetitationIntervals}
                  label="Powtarzaj"
                  style={{ width: "280px", paddingBottom: "9px" }}
                  // @ts-ignore
                  selectedItem={repetitationInterval}
                  // @ts-ignore
                  setSelectedItem={setRepetitationInterval}
                  defaultValue={event.RepetitionInterval}
                />
              </SingleColumn>

              <SingleColumn style={{ alignItems: "center" }}>
                <p>UCZESTNICY</p>
                <NewSelectStatystics
                  name="Visibility"
                  items={visibilities}
                  label="Widoczność"
                  style={{ width: "280px", paddingBottom: "9px" }}
                  // @ts-ignore
                  selectedItem={visibility}
                  setSelectedItem={setVisibility}
                  defaultValue={event.Visibility}
                />

                {visibility?.value === 2 && (
                  <>
                    {/* <NewSelectStatystics
                      name="Grupa"
                      items={academyFolders}
                      label="Grupa"
                      style={{ width: "280px", paddingBottom: "9px" }}
                      // @ts-ignore
                      selectedItem={academyFolder}
                      setSelectedItem={setAcademyFolder}
                    />

                    <PlayersBox>
                      {allFolders.map((folder: any) => (
                        <PlayerBox>
                          <div>
                            <p>{folder.Name}</p>
                          </div>
                          <div onClick={() => deleteFolder(folder.Id)}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="20.571"
                              viewBox="0 0 16 20.571"
                            >
                              <path
                                id="Icon_material-delete"
                                data-name="Icon material-delete"
                                d="M8.643,22.786a2.292,2.292,0,0,0,2.286,2.286h9.143a2.292,2.292,0,0,0,2.286-2.286V9.071H8.643ZM23.5,5.643h-4L18.357,4.5H12.643L11.5,5.643h-4V7.929h16Z"
                                transform="translate(-7.5 -4.5)"
                                fill="#dc2121"
                              />
                            </svg>
                          </div>
                        </PlayerBox>
                      ))}
                    </PlayersBox> */}
                    <Button
                      onClick={() => setIsGroupModalOpen(true)}
                      variant={ButtonVariant.Submit}
                      style={{
                        borderRadius: 0,
                        width: "280px",
                        fontSize: "15px",
                        fontWeight: "bold",
                        marginTop: "13px",
                        color: "white",
                        background: "rgb(0, 160, 255)",
                        marginBottom: "8px",
                      }}
                    >
                      Wybierz grupy
                    </Button>
                  </>
                )}

                {visibility?.value === 1 && (
                  <>
                    <InputWithSearchResultsWrapper
                      onBlur={onBlur}
                      // style={{ marginBottom: "10px" }}
                    >
                      <RightSectionAddressAndTitleWriteMode>
                        {/* <img src={logo} alt={logo} /> */}
                        <p>Wyszukaj</p>
                        <input
                          type="text"
                          placeholder="Nazwa"
                          autoComplete="off"
                          value={receiver}
                          onFocus={() => handleOnFocusChange(true)}
                          onChange={handleReceiverChange}
                        />
                        <div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24.75"
                            height="18"
                            viewBox="0 0 24.75 18"
                          >
                            <path
                              id="Icon_material-person-add"
                              data-name="Icon material-person-add"
                              d="M17.25,15a4.5,4.5,0,1,0-4.5-4.5A4.5,4.5,0,0,0,17.25,15ZM7.125,12.75V9.375H4.875V12.75H1.5V15H4.875v3.375h2.25V15H10.5V12.75Zm10.125,4.5c-3,0-9,1.508-9,4.5V24h18V21.75C26.25,18.757,20.254,17.25,17.25,17.25Z"
                              transform="translate(-1.5 -6)"
                              fill="#fff"
                            />
                          </svg>
                        </div>
                      </RightSectionAddressAndTitleWriteMode>
                      <SearchResultWrapper
                        isFocused={isFocused}
                        autoCompleted={receiver.length > 1}
                        tabIndex={0}
                      >
                        {playerProfiles.length === 0 && receiver.length > 1 && (
                          <p
                            style={{
                              color: "white",
                              fontSize: "13px",
                              fontWeight: 500,
                            }}
                          >
                            Brak wyników
                          </p>
                        )}

                        {playerProfiles.map((playerProfile: any) => {
                          const { PlayerId, Name, Type, FtpPhotoFilePath } =
                            playerProfile;

                          return (
                            <SingleSearchResultWrapper
                              key={PlayerId}
                              onClick={() => {
                                handleReceiverIdChange(PlayerId);
                                setReceiver(`${Name}`);
                                setPlayerImage("");
                                setPlayerType(`1`);
                                toggleFocused(false);
                                // createNewConversation(Id, 1);
                                // toggleAddNewConversation(false);
                              }}
                            >
                              <p>{`${Name}`}</p>
                            </SingleSearchResultWrapper>
                          );
                        })}
                      </SearchResultWrapper>
                    </InputWithSearchResultsWrapper>

                    <PlayersBox>
                      {allPlayers.map((player: any) => (
                        <PlayerBox>
                          {/* <img
                        src={player?.Image ? player.Image : logo}
                        alt=""
                        style={{ width: "48px", height: "48px" }}
                      /> */}
                          <div>
                            <p>{player.Name}</p>
                          </div>
                          <div onClick={() => deletePlayer(player.Id)}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="20.571"
                              viewBox="0 0 16 20.571"
                            >
                              <path
                                id="Icon_material-delete"
                                data-name="Icon material-delete"
                                d="M8.643,22.786a2.292,2.292,0,0,0,2.286,2.286h9.143a2.292,2.292,0,0,0,2.286-2.286V9.071H8.643ZM23.5,5.643h-4L18.357,4.5H12.643L11.5,5.643h-4V7.929h16Z"
                                transform="translate(-7.5 -4.5)"
                                fill="#dc2121"
                              />
                            </svg>
                          </div>
                        </PlayerBox>
                      ))}
                    </PlayersBox>
                  </>
                )}
                {/* <TextInput
              name="OccurenceDate"
              label={"Od data"}
              type="date"
              value={availableFrom}
              onChange={(e) => setAvailableFrom(e.target.value)}
            />
            <TextInput
              name="EndDate"
              label={"Do data"}
              type="date"
              value={availableTo}
              onChange={(e) => setAvailableTo(e.target.value)}
            /> */}

                <p>LICZBA ZAPROSZEŃ</p>
                <CheckBoxDiv>
                  <div>
                    <p>Limit miejsc</p>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      name="limit"
                      id="limit"
                      checked={isLimitChecked}
                      onChange={handleLimitCheckboxChange}
                    />
                  </div>
                </CheckBoxDiv>

                {/* <NewSelectStatystics
                    name="Limit"
                    items={limits}
                    label="Limit"
                    style={{ width: "280px", paddingBottom: "9px" }}
                    // @ts-ignore
                    selectedItem={limit}
                    // @ts-ignore
                    setSelectedItem={setLimit}
                  /> */}
                {isLimitChecked && (
                  // <TextField name="CountLimit" label={"Liczba miejsc"} />
                  <TextField name="Limit.Limit" label={"Liczba miejsc"} />
                )}

                {/* <NewSelectStatystics
                name="Limit"
                items={limits}
                label="Limit"
                style={{ width: "280px", paddingBottom: "9px" }}
                // @ts-ignore
                selectedItem={limit}
                // @ts-ignore
                setSelectedItem={setLimit}
                defaultValue={event?.Limit?.HasLimit}
              />
              {limit?.value && (
                <TextField
                  isError={false}
                  name="Limit.Limit"
                  label={"Limit uczestników"}
                />
              )} */}
                <p>KALENDARZ</p>
                <CheckBoxDiv>
                  <div>
                    <p>Wpis do kalendarza</p>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      name="calendar"
                      id="calendar"
                      checked={isCalendarChecked}
                      onChange={handleCalendarCheckboxChange}
                    />
                  </div>
                </CheckBoxDiv>
                {isCalendarChecked && (
                  <SelectWithoutFilter
                    name="DaysBeforeEvent"
                    items={allDaysBeforeEvent}
                    label="Na ile dni przed eventem"
                    style={{ width: "280px", paddingBottom: "9px" }}
                    // @ts-ignore
                    selectedItem={daysBeforeEvent}
                    setSelectedItem={setDaysBeforeEvent}
                    defaultValue={event?.TimeInterval}
                  />
                )}
              </SingleColumn>

              <SingleColumn style={{ alignItems: "center" }}>
                <div
                  style={{
                    width: "280px",
                    paddingBottom: "9px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <p
                    style={{
                      textTransform: "uppercase",
                      fontSize: "15px",
                      fontWeight: 400,
                      width: "calc(280px - 22.5px)",
                    }}
                  >
                    POST
                  </p>
                  <input
                    style={{ width: "22.5px", height: "22.5px" }}
                    type="checkbox"
                    name="post"
                    id="post"
                    checked={isPostChecked}
                    onChange={handlePostCheckboxChange}
                  />
                </div>
                {isPostChecked && (
                  <>
                    <NewImageField
                      name="photos"
                      titleName="Grafika zajęcia"
                      className="mx-auto md:mx-0 mb-8"
                      imgSrc={
                        values?.image
                          ? values?.image
                          : event?.Files[0]?.FilePath
                      }
                      // imgSrc={
                      //   values?.image
                      //     ? values.image
                      //     : event.Files.lenght > 0
                      //     ? event.Files[0].FilePath
                      //     : ""
                      // }
                      base64={base64}
                      setBase64={setBase64}
                    />
                    <TextAreaField
                      name="Content"
                      label={"Opis"}
                      isError={false}
                      style={{ paddingBottom: "9px" }}
                    />
                  </>
                )}
                <TextAreaField
                  name="Note"
                  label={"Uwagi"}
                  isError={false}
                  style={{ paddingBottom: "9px" }}
                />
              </SingleColumn>
            </ColumnsContainer>
          </Container>
          <div
            style={{
              display: "flex",
              marginLeft: "15px",
              height: "65px",
              minHeight: "65px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",

                height: "100%",
                borderTop: "5px solid rgba(163,158,157,0.3)",
                width: "calc(100% - 59px)",
              }}
            >
              <Button
                onClick={async () => {
                  await cyclicalEventServices.deleteCyclicalEvent(
                    event?.EventId,
                  );
                  window.location.reload();
                }}
                variant={ButtonVariant.Delete}
                style={{
                  width: "280px",
                  marginTop: "13px",
                  marginRight: "8px",
                }}
              >
                Usuń
              </Button>

              <SubmitButton
                isSubmitting={isSubmitting}
                variant={ButtonVariant.Save}
                style={{
                  width: "280px",
                  marginTop: "13px",
                }}
                submitText="Tworzenie zajęcia..."
              >
                Utwórz zajęcie
              </SubmitButton>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default EditDraftEventForm;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 70px);
  overflow-y: auto;
  gap: 20px;

  &::-webkit-scrollbar {
    width: 0;
  }
`;

const ColumnsContainer = styled.div`
  display: flex;
  width: 100%;
  height: calc(100% - 70px);
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 0;
  }
`;

const NameBox = styled.div`
  padding: 0 15px;
  display: flex;
  gap: 20px;
  /* align-items: center; */
`;

const InputWithSearchResultsWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > p {
    font-size: 11px;
    color: white;
    width: 80px;
  }

  & > input {
    color: white;
    font-size: 11px;
    width: 100%;
    outline: none;
    background: transparent;
    border: none;
    color: white;
  }
`;

const PlayersBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 280px;
  /* height: 250px; */
  max-height: 250px;
  overflow-y: auto;
  /* background: red; */
  margin-top: 9px;
  margin-bottom: 9px;
`;

const PlayerBox = styled.div`
  width: 100%;
  height: 48px;
  min-height: 48px;
  /* background: blue; */
  background: rgba(163, 158, 157, 0.1);
  cursor: pointer;
  margin-bottom: 9px;
  display: flex;

  & > img {
    width: 48px;
    height: 48px;
  }

  & > div:nth-of-type(1) {
    width: calc(100% - 52px);
    height: 100%;
    display: flex;
    align-items: center;

    & > p {
      color: #0091ff;
      padding-left: 10px;
      width: 100%;
      margin-right: 17px;
      text-align: right;
      font-size: 13px;
      font-weight: 600;
    }
  }

  & > div:nth-of-type(2) {
    width: 52px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(163, 158, 157, 0.3);
  }
`;

const RightSectionAddressAndTitleWriteMode = styled.div`
  display: flex;
  width: 100%;
  background: rgba(163, 158, 157, 0.1);
  height: 48px;
  position: relative;

  & > div {
    height: 100%;
    width: 52px;
    background: #0091ff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  /* padding: 5px 11.5px; */
  /* border-radius: 5px; */
  /* border: 1px solid rgba(112, 112, 112, 0.3); */

  & > p {
    font-size: 11px;
    color: rgb(163, 158, 157);
    position: absolute;
    top: 7px;
    left: 9px;
    font-weight: 400;
    width: 80px;
  }
  & > img {
    width: 48px;
    height: 100%;
    /* background: white; */
  }

  & > input {
    color: white;
    text-align: right;
    font-size: 13px;
    font-weight: 600;
    padding-right: 17px;
    width: calc(100% - 52px);
    outline: none;
    background: transparent;
    border: none;

    &::placeholder {
      color: white;
      opacity: 1;
    }
  }
`;

const SingleSearchResultWrapper = styled.div`
  width: 100%;
  height: 20px;
  background: rgba(163, 158, 157, 0.1);
  /* border-radius: 5px; */
  padding-left: 20px;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  z-index: 5;
  cursor: pointer;

  & > p {
    font-size: 11px;
    color: white;
  }

  &:hover {
    /* background: #16265c; */
    background: #89bfff;
  }
`;

const CheckBoxDiv = styled.div`
  width: 280px;
  height: 48px;
  display: flex;
  align-items: center;

  & > div:nth-of-type(1) {
    width: calc(100% - 46px);
    text-align: right;

    & > p {
      color: white;
      font-size: 15px;
      font-weight: 600;
    }
  }

  & > div:nth-of-type(2) {
    width: 46px;
    display: flex;
    justify-content: center;
    align-items: center;

    & > input {
      width: 28px;
      height: 28px;
    }
  }
`;

const SearchResultWrapper = styled.div<{
  isFocused: boolean;
  autoCompleted: boolean;
}>`
  width: 100%;
  position: absolute;
  background: rgba(2, 2, 15, 1);
  /* border-radius: 5px; */
  /* border: 1px solid #e1e1e1; */
  backdrop-filter: blur(3px);
  color: white;
  padding: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 51px;
  /* padding: 5px 0; */
  z-index: 5;
  visibility: ${({ autoCompleted, isFocused }) =>
    autoCompleted && isFocused ? "visible" : "hidden"};
  display: ${({ autoCompleted, isFocused }) =>
    autoCompleted && isFocused ? "flex" : "none"};
`;
const DateBox = styled.div`
  width: 280px;
  height: 48px;
  min-height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  input {
    font-size: 12px;
    text-align: right;
    padding-right: 0 !important;
  }

  & > div {
    width: 136px;
    & > div {
      min-width: 136px !important;
    }
  }
`;
