import HeaderContainer from 'components/common/Containers/HeaderContainer';
import ColumnTableRow from 'components/common/FormTable/ColumnTableRow';
import Button from 'components/common/buttons/basicButton/Button';
import { ButtonVariant } from 'components/common/buttons/buttonTypes';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import passService from 'services/transfer/passServices';
import { GridColumn, StyledTableSelect, GridTextItem } from 'styles/styles';
import { IPass, IPlayerHistoryPass } from 'types/transfer/pass.interface';
import { IAppliedPass } from 'types/transfer/player.interface';
import { showServerErrors } from 'utils/errorsUtils';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import 'styles/confirmAlertStyles.scss'; // Import css
import { getFormatedDate } from 'utils/dateUtils';
import styled from 'styled-components';

import { ReactComponent as DeleteIcon } from '../../../../../assets/icons/delete.svg';

import { ReactComponent as SaveIcon } from '../../../../../assets/icons/save.svg';

import { useSelector } from 'react-redux';
import { useAcademyTabsContext } from 'components/academy/hook/useAcademyTabsContext';
import { toast } from 'react-toastify';
import DeleteButton from 'components/common/buttons/basicButton/DeleteButton';
import SaveButton from 'components/common/buttons/basicButton/SaveButton';
import EditButton from 'components/common/buttons/basicButton/EditButton';

const StyledInput = styled.input`
  background: transparent;
  border: 1px solid rgba(163, 158, 157, 0.3);
  width: 100%;
  border-radius: 5px;
  height: 38px;
  opacity: 1;
  text-align-last: center;
  text-align: center;
  -webkit-appearance: none;
  appearance: none;
  outline: none;

  & > * {
    background: rgb(57, 57, 65);
    opacity: 0.5;
    /* height: 48px; */
  }
`;

const StyledInputDate = styled.input`
  background: transparent;
  border: 1px solid rgba(163, 158, 157, 0.3);
  width: 100%;
  border-radius: 5px;
  height: 40px;
  opacity: 1;
  text-align-last: center;
  text-align: center;
  -webkit-appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  color-scheme: dark;
`;

interface IApplyPassProps {
  refresh: () => Promise<void>;
  // appliedPass: IAppliedPass;
  appliedPass: any;
  newestHistoryPass: IPlayerHistoryPass;
}

enum PassType {
  Miesięczny = 0,
  Czasowy = 1,
}

enum PaymentType {
  Miesięczna = 0,
  Rata = 1,
  Pełna = 2,
}

function formatDate(isoString: any) {
  const date = new Date(isoString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

const formatToISOWithoutMilliseconds = (dateStr: any) => {
  const [year, month, day] = dateStr.split('-');
  const utcDate = new Date(Date.UTC(year, month - 1, day, 0, 0, 0));
  return utcDate.toISOString().slice(0, 19) + 'Z';
};

const ApplyPass = ({
  refresh,
  appliedPass,
  newestHistoryPass,
}: IApplyPassProps) => {
  const { id: playerId } = useParams<{ id: string }>();

  const [passes, setPasses] = useState<any[] | null>([]);
  const [isLoading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [newDate, setNewDate] = useState('');
  const [editedPassEndAt, setEditedPassEndAt] = useState('');
  const [isPassEndAtEdit, setIsPassEndAtEdit] = useState(false);
  const [idEditedPassEndAt, setIdEditedPassEndAt] = useState('');
  const [prevEditPassEnd, setPrevEditPassEnd] = useState('');
  const [isTableEdit, setIsTableEdit] = useState(false);
  const [newPass, setNewPass] = useState(false);
  const [editRow, setEditRow] = useState('');
  const [pickedPass, setPickedPass] = useState<any | null>(null);
  const [isRemoveLoading, setIsRemoveLoading] = useState(false);
  const [isEditEndPassInput, setIsEditEndPassInput] = useState(false);

  const permission = useSelector((state: any) => state.permissions.permissions);

  const isAcademyCreatePass = passes?.length === 0;

  const isElemHasPermission = (id: number) =>
    permission[0]?.Id === -1
      ? true
      : !!permission.find((permission: any) => permission.Id === id);

  // const { data  } = useAcademyTabsContext();

  const contactMail = 'support@academiq.pl';
  const mailtoHref = `mailto:${contactMail}?subject=${'academy.Name'} - Edycja składki.`;

  const findLastDate = appliedPass?.AppliedPasses.map((elem: any) => {
    let date = null;

    if (elem.Period === null) {
      date = formatDate(elem.EndAt);
    } else {
      date = formatDate(elem.Period.EndAt);
    }
    return date;
  });

  const handleApplyPassDate = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isAnyPassApplied = appliedPass?.AppliedPasses.length > 0;

    if (isAnyPassApplied) {
      const compareDate = appliedPass?.AppliedPasses.some((elem: any) => {
        const passStartAt = formatDate(elem.StartAt);
        const passEndAt = formatDate(elem.EndAt);
        return (
          event.target.value < passStartAt || event.target.value > passEndAt
        );
      });

      if (compareDate) {
        setNewDate(event.target.value);
      } else {
        toast.error(
          'Data aktywacji nie może być wcześniejsza niż data rozpoczęcia juz przpisanej składki'
        );
        setNewDate('');
      }
    } else {
      const compareDate = [pickedPass].some((elem) => {
        const todayDate = formatDate(new Date());

        if (elem.Period.EndAt === null) {
          return todayDate <= event.target.value;
        } else {
          return formatDate(elem.Period.StartAt) === event.target.value;
        }
      });
      if (compareDate) {
        setNewDate(event.target.value);
      } else {
        setNewDate('');
        return toast.error('Data aktywacji musi byc datą początku składki');
      }
    }
  };

  const handelSetNewEndPassDate = async (PassId: string) => {
    setLoading(true);

    const passEnds = formatToISOWithoutMilliseconds(editedPassEndAt);

    try {
      const payload: { PlayerId: string; PassId: string; EndAt: string } = {
        PlayerId: playerId,
        PassId: PassId,
        EndAt: passEnds,
      };

      await passService.setPassEndDate(payload);
      toast.success(`Data zakończenia karnetu została zmieniona`);

      await refresh();
      setPasses([]);
      getAcademyPasses();
      setLoading(false);
      setNewPass(false);
      setEditedPassEndAt('');
      setPrevEditPassEnd('');
      setIsTableEdit(false);
      setIsEditEndPassInput(false);
    } catch (error: any) {
      showServerErrors(error);
      setLoading(false);
      setNewPass(false);
    }
  };

  const handleEditPassEndAt = (
    event: React.ChangeEvent<HTMLInputElement>,
    editedPassId: string
  ): void => {
    const isInvalidDate = appliedPass?.AppliedPasses.some((elem: any) => {
      if (elem.PassId === editedPassId) {
        if (elem.Period === null) {
          return (
            event.target.value < formatDate(elem.StartAt) ||
            event.target.value < formatDate(elem.StartAt)
          );
        } else {
          return (
            formatDate(elem.Period.StartAt) > event.target.value ||
            event.target.value < formatDate(elem.StartAt)
          );
        }
      }
    });

    if (isInvalidDate) {
      toast.error('Podana data jest nie błędna');
      setEditedPassEndAt('');
      setIsEditEndPassInput(false);
    } else {
      setEditedPassEndAt(event.target.value);
    }
  };

  const showWarningPopup = () => {
    confirmAlert({
      title: 'Dodanie Składki',
      message:
        'Dodanie składki jest niemożliwe, ponieważ nakłada się na już istniejącą. Nową składkę można dodać dopiero po zakończeniu bieżącego okresu. Po więcej informacji zachęcamy do kontaktu bezpośrednio z obsługą klienta pod adresem: support@academiq.pl',
      buttons: [
        {
          label: 'Wyślij wiadomość',
          onClick: () => {
            window.location.href = mailtoHref;
          },
        },

        {
          label: 'Rozumiem',
          onClick: () => {},
        },
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
    });
  };

  const showWarningPopupDeletePass = () => {
    confirmAlert({
      title: 'Dodanie Składki',
      message:
        'Usunięcie składki podczas jej trwanie jest nie możliwe. Po więcej informacji zachęcamy do kontaktu bezpośrednio z obsługą klienta pod adresem: support@academiq.pl',
      buttons: [
        {
          label: 'Wyślij wiadomość',
          onClick: () => {
            window.location.href = mailtoHref;
          },
        },
        {
          label: 'Rozumiem',
          onClick: () => {},
        },
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
    });
  };

  const showPassLackPopup = () => {
    confirmAlert({
      title: 'Brak Składki',
      message:
        'Aktualnie akademia nie posiada żadnych składek . Przejdź do sekcji moja akademia i utwórz nową składkę ',
      buttons: [
        {
          label: 'Rozumiem',
          onClick: () => {},
        },
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
    });
  };

  const getAcademyPasses = async () => {
    const { Items } = await passService.getAll();

    console.log(Items, 'items........');
    if (Items) {
      Items.map((item, index) => {
        if (!item?.IsAssignedToAnyPlayer) {
          setPasses((prev: any) => [...prev, item]);
        }
      });
    }
  };

  const applyPass = async (passDate: string, passId: string) => {
    setLoading(true);

    try {
      await passService.applyPlayer(
        playerId,
        passId,
        formatToISOWithoutMilliseconds(newDate)
      );
      toast.success(
        `Karnet ${
          passes?.find((pass) => pass.PassId === passId)?.Name
        } został przypisany i oczekuje na zatwierdzenie przez zawodnika`
      );

      await refresh();
      setPasses([]);
      getAcademyPasses();
      setLoading(false);
      setNewPass(false);
    } catch (error: any) {
      showServerErrors(error);
      setLoading(false);
      setNewPass(false);
    }
  };

  const revokePass = async (payload: string) => {
    setIsRemoveLoading(true);
    try {
      await passService.revokePlayer(playerId, payload);
      toast.success(`Pomyślnie usunięto ${appliedPass.Name}`);
      await refresh();
      setPasses([]);
      getAcademyPasses();

      setEditRow('');
    } catch (error: any) {
      showServerErrors(error);
    } finally {
      setIsRemoveLoading(false);
      setNewPass(false);
    }
  };

  useEffect(() => {
    getAcademyPasses();
  }, []);

  return (
    <div
      className={`flex lg:w-full overflow-x-auto md:overflow-x-hidden w-[50vw]`}
    >
      <HeaderContainer
        title={appliedPass ? 'Przypisz składkę' : 'Aktualna składka'}
        headerContent={
          <div className="flex h-full p-4 gap-2">
            <div
              style={{
                pointerEvents: isElemHasPermission(179) ? 'auto' : 'none',
                opacity: isElemHasPermission(179) ? '' : '0.6',
              }}
            >
              {!isAcademyCreatePass ? (
                <Button
                  onClick={() => {
                    setNewPass((prev) => !prev);
                    setPickedPass(null);
                    setNewDate('');

                    // setEditedPassEndAt('');
                    // setIsPassEndAtEdit(false);
                    // setIdEditedPassEndAt('');
                    // setIsTableEdit(false);
                  }}
                  variant={!newPass ? ButtonVariant.Add : ButtonVariant.Cancel}
                >
                  {!newPass ? 'Dodaj składkę' : 'Zakończ'}
                </Button>
              ) : (
                <Button
                  variant={ButtonVariant.Add}
                  onClick={() => {
                    showPassLackPopup();
                  }}
                >
                  Dodaj składkę
                </Button>
              )}
            </div>
            {appliedPass?.AppliedPasses.length ? (
              <div
                style={{
                  pointerEvents: isElemHasPermission(180) ? 'auto' : 'none',
                  opacity: isElemHasPermission(180) ? '' : '0.6',
                }}
              >
                <Button
                  onClick={() => {
                    setIsTableEdit((prev) => {
                      if (prev) {
                        setIsEditing(false);
                        setEditedPassEndAt('');
                      }
                      return !prev;
                    });
                  }}
                  variant={
                    !newPass && isTableEdit
                      ? ButtonVariant.Cancel
                      : ButtonVariant.Edit
                  }
                >
                  {isTableEdit && !newPass ? 'Zakończ' : 'Edycja'}
                </Button>
              </div>
            ) : null}
          </div>
        }
      >
        {appliedPass?.AppliedPasses ? (
          <GridColumn cols={9}>
            <GridTextItem isHeader>Nazwa</GridTextItem>
            <GridTextItem isHeader>typ</GridTextItem>
            <GridTextItem isHeader>Od</GridTextItem>
            <GridTextItem isHeader>Do</GridTextItem>
            <GridTextItem isHeader>Płatność</GridTextItem>
            <GridTextItem isHeader>Brutto</GridTextItem>
            <GridTextItem isHeader>Data aktywacji</GridTextItem>
            <GridTextItem isHeader>Data zakończenia</GridTextItem>
            <GridTextItem isHeader>Działanie</GridTextItem>
            <>
              {newPass && passes !== null
                ? passes.slice(0, 1).map((elem: any, idx: number) => (
                    <React.Fragment key={elem.PassId}>
                      <GridTextItem>
                        <ColumnTableRow header="" disableUppercase>
                          <>
                            <StyledTableSelect
                              value={pickedPass ? pickedPass?.PassId : ''}
                              onChange={(e) => {
                                const selectedPassId = e.target.value;
                                const selectedPass = passes.find(
                                  (pass) => pass.PassId === selectedPassId
                                );
                                setPickedPass(selectedPass);
                              }}
                              style={{ lineHeight: '15px' }}
                            >
                              <option value="" disabled>
                                Wybierz składkę
                              </option>
                              {passes.map((pass) => (
                                <option key={pass.PassId} value={pass.PassId}>
                                  {pass.Name}
                                </option>
                              ))}
                            </StyledTableSelect>
                          </>
                        </ColumnTableRow>
                      </GridTextItem>

                      <GridTextItem>
                        {pickedPass !== null ? PassType[pickedPass?.Type] : '-'}
                      </GridTextItem>

                      <GridTextItem>
                        {pickedPass?.Period?.StartAt
                          ? getFormatedDate(pickedPass?.Period?.StartAt, 'date')
                          : '-'}
                      </GridTextItem>
                      <GridTextItem>
                        {pickedPass?.Period.EndAt
                          ? getFormatedDate(pickedPass?.Period.EndAt, 'date')
                          : '-'}
                      </GridTextItem>
                      <GridTextItem>
                        {pickedPass !== null
                          ? PaymentType[pickedPass?.PaymentType]
                          : '-'}
                      </GridTextItem>
                      <GridTextItem>
                        {pickedPass?.Gross}

                        {pickedPass !== null ? 'pln' : '-'}
                      </GridTextItem>
                      <GridTextItem>
                        {pickedPass !== null && (
                          <StyledInputDate
                            type="date"
                            value={newDate}
                            onChange={handleApplyPassDate}
                          />
                        )}
                      </GridTextItem>
                      <GridTextItem>-</GridTextItem>
                      <GridTextItem>
                        <div className="flex items-center w-full h-full justify-center">
                          {pickedPass !== null && newDate !== '' && (
                            <SaveButton
                              variant={ButtonVariant.Cancel}
                              disabled={isLoading}
                              onClick={() => {
                                if (findLastDate[0] >= newDate) {
                                  return toast.error(
                                    'Data składki musi zaczynać się dzień po skończeniu aktualnej składki'
                                  );
                                } else {
                                  applyPass(
                                    formatToISOWithoutMilliseconds(newDate),
                                    pickedPass.PassId
                                  );
                                }
                              }}
                            />
                          )}
                        </div>
                      </GridTextItem>
                    </React.Fragment>
                  ))
                : null}
            </>

            <>
              {appliedPass?.AppliedPasses.map((elem: any, idx: number) => (
                <React.Fragment key={elem.PassId}>
                  <GridTextItem>{elem.Name}</GridTextItem>
                  <GridTextItem>{PassType[elem.Type]}</GridTextItem>

                  <GridTextItem>
                    {elem.Period !== null
                      ? getFormatedDate(elem.Period.StartAt, 'date')
                      : '-'}
                  </GridTextItem>

                  <GridTextItem>
                    {elem.Period !== null
                      ? getFormatedDate(elem.Period.EndAt, 'date')
                      : '-'}
                  </GridTextItem>

                  <GridTextItem>{PaymentType[elem.PaymentType]}</GridTextItem>

                  <GridTextItem>{elem.Gross} pln</GridTextItem>

                  {isEditing && elem.PassId === editRow ? (
                    <GridTextItem>
                      <StyledInputDate
                        type="date"
                        value={newDate}
                        onChange={handleApplyPassDate}
                      />
                    </GridTextItem>
                  ) : (
                    <GridTextItem>
                      {elem.StartAt
                        ? getFormatedDate(elem.StartAt, 'date')
                        : '-'}
                    </GridTextItem>
                  )}

                  <GridTextItem>
                    {elem.EndAt && !isEditEndPassInput ? (
                      getFormatedDate(elem.EndAt, 'date')
                    ) : idEditedPassEndAt === elem.PassId ? (
                      <StyledInputDate
                        type="date"
                        value={editedPassEndAt}
                        onChange={(event) =>
                          handleEditPassEndAt(event, elem.PassId)
                        }
                      />
                    ) : (
                      '-'
                    )}
                  </GridTextItem>

                  <GridTextItem>
                    <div className="flex w-full h-full gap-2 items-center justify-center ">
                      {isTableEdit && (
                        <>
                          {idEditedPassEndAt === elem.PassId &&
                          editedPassEndAt.length > 0 ? (
                            <SaveButton
                              onClick={() =>
                                handelSetNewEndPassDate(elem.PassId)
                              }
                            />
                          ) : (
                            <EditButton
                              onClick={() => {
                                setIdEditedPassEndAt(elem.PassId);
                                setIsPassEndAtEdit(!isPassEndAtEdit);

                                setIsEditEndPassInput((prev) => !prev);

                                console.log('edition');

                                if (
                                  getFormatedDate(elem.EndAt, 'date').length > 0
                                ) {
                                  setPrevEditPassEnd(
                                    getFormatedDate(elem.EndAt, 'date')
                                  );

                                  console.log('edition111', prevEditPassEnd);
                                }
                              }}
                            />
                          )}
                          <DeleteButton
                            onClick={() => {
                              const todadDate = new Date();
                              const applyPasses =
                                appliedPass.AppliedPasses.some((elem: any) => {
                                  if (elem.Period !== null) {
                                    const startDate = new Date(
                                      elem.Period.StartAt
                                    );
                                    const EndDate = new Date(elem.Period.EndAt);

                                    const appliedPassStartOnly = new Date(
                                      todadDate.getFullYear(),
                                      todadDate.getMonth(),
                                      todadDate.getDate()
                                    );

                                    const getStartPassDate = new Date(
                                      startDate.getFullYear(),
                                      startDate.getMonth(),
                                      startDate.getDate()
                                    );

                                    const getEndPassDate = new Date(
                                      EndDate.getFullYear(),
                                      EndDate.getMonth(),
                                      EndDate.getDate()
                                    );
                                    return (
                                      appliedPassStartOnly < getStartPassDate ||
                                      appliedPassStartOnly > getEndPassDate
                                    );
                                  }
                                  if (elem.Period === null) {
                                    const startDate = formatDate(
                                      new Date(elem.StartAt)
                                    );

                                    const todayDate = formatDate(new Date());
                                    return todayDate < startDate;
                                  }
                                });

                              if (applyPasses) {
                                revokePass(elem.PassId);
                              } else {
                                showWarningPopupDeletePass();
                              }
                            }}
                            variant={ButtonVariant.Cancel}
                            disabled={isRemoveLoading}
                          />
                        </>
                      )}
                    </div>
                  </GridTextItem>
                </React.Fragment>
              ))}
            </>
          </GridColumn>
        ) : (
          <div>brak danych</div>
        )}
      </HeaderContainer>
    </div>
  );
};

export default ApplyPass;
