import React, { useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { ReactComponent as SortIcon } from "../../assets/icons/sort-icon.svg";
import styled from "styled-components";
import { getFormatedDate } from "utils/dateUtils";
import Button from "components/common/buttons/basicButton/Button";
import { ButtonVariant } from "components/common/buttons/buttonTypes";
import notificationsServices from "services/notificationsServices";
import { useDispatch } from "react-redux";
import { Notification } from "types/notificatonTypes";
import LoaderRing from "components/common/loaderRing/LoaderRing";
import ContentContainer from "components/layout/ContentContainer";
import { setNotificationCounter } from "../../store/actions/notificationCounter";
import { toast } from "react-toastify";
import Notifications from "components/navbar/Notifications";
import { useDebounce } from "use-debounce";
import useInfiniteScroll from "hooks/useInfiniteScroll";
import DeleteButton from "components/common/buttons/basicButton/DeleteButton";

interface Notifications {
  Description: string;
  IsComplete: boolean;
  IsSee: boolean;
  NotificationId: string;
  SentAt: string;
  TypeObjectId: string;
  email?: string;
  Name?: string;
  Type?: number | undefined;
}

const NotificationCenter: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [visibility, setVisibility] = useState("Wszystkie");
  const viewLabels = ["Wszystkie", "Wysłana", "Przeczytana"];
  const [isOpen, setIsOpen] = useState(false);
  const [item, setItems] = useState<Notifications[]>([]);
  const [previousVisibility, setPreviousVisibility] = useState(visibility);
  const [nextPage, setNextPage] = useState(1);
  const [queryString, setQueryString] = useState("");

  const endpoint = useMemo(() => {
    switch (visibility) {
      case "Wszystkie":
        return notificationsServices.getAllNotifications;

      case "Wysłana":
        return notificationsServices.getNotificationsIsComplete;

      case "Przeczytana":
        return notificationsServices.getNotificationsIsSee;

      default:
        return notificationsServices.getAllNotifications;
    }
  }, [visibility]);
  const [debounceSearchInput] = useDebounce(queryString, 500);

  const {
    items: notifications,
    loading,
    containerRef,
    lastItemRef,
    refresh,
    metaInfo,
    reset,
  } = useInfiniteScroll<any>(endpoint, debounceSearchInput);

  useEffect(() => {
    if (visibility !== previousVisibility) {
      refresh();
      setPreviousVisibility(visibility);
      setNextPage(1);
      setItems([]);
    }
  }, [visibility, previousVisibility]);

  useEffect(() => {
    if (metaInfo.pageNumber !== nextPage && metaInfo.HasNextPage) {
      setNextPage((prev) => prev + 1);
      refresh();
    } else {
      setItems((prev: any) => [...prev, ...notifications]);
    }
  }, [metaInfo, nextPage]);

  const moduleName = (elem: any): string => {
    let module = "";
    switch (elem) {
      case 0:
      case 6:
      case 4:
      case 2:
        module = "Zawodnicy";
        break;
      case 14:
      case 15:
        module = "Sklep";
        break;
      case 8:
      case 9:
      case 10:
      case 11:
        module = "Trenerzy";
        break;
      default:
        module = "UNKNOWN";
        break;
    }
    return module;
  };

  const handleNotificationDelete = async (
    elem: string | undefined,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): Promise<void> => {
    event.preventDefault();
    event.stopPropagation();
    if (elem !== undefined) {
      try {
        await notificationsServices.remove(elem);
        setNextPage(1);
        setItems([]);
        refresh();
        dispatch(setNotificationCounter(true));
        dispatch(setNotificationCounter(false));

        toast.success("Powiadmomienie zostało usunięte!");
      } catch (error) {
        console.error("Error removing notification:", error);
        toast.error("Failed to remove notification.");
      }
    }
  };

  const redirectUserTo = async (elem: any) => {
    try {
      if (!elem.IsSee && elem.NotificationId) {
        await notificationsServices.seeNotification(elem.NotificationId);
        dispatch(setNotificationCounter(true));
        dispatch(setNotificationCounter(false));
      }

      let redirectLink = "";

      switch (elem.Type) {
        case 0:
        case 6:
        case 4:
        case 2:
          redirectLink = `/players/detail/${elem.TypeObjectId}`;
          break;
        case 14:
        case 15:
          redirectLink = `/shop/products/detail/${elem.TypeObjectId}`;
          break;
        case 12:
        case 13:
          redirectLink = `/shop/products/detail/${elem.TypeObjectId}?tab=passes`;
          break;
        case 8:
        case 9:
        case 10:
        case 11:
          redirectLink = `/trainers/detail/${elem.TypeObjectId}`;
          break;
        default:
          redirectLink = "/error";
          break;
      }

      history.push(redirectLink);
    } catch (error) {
      console.error("Failed to see notification or redirect:", error);
    }
  };

  const displayStatus = (IsSee: boolean, IsComplete: boolean): string => {
    let status = "";
    switch (true) {
      case !IsSee && !IsComplete:
        status = "Wysłana";
        break;
      case IsComplete:
        status = "Odebrana";
        break;
      case IsSee:
        status = "Przeczytana";
        break;
      default:
        status = "";
        break;
    }
    return status;
  };

  return (
    <ContentContainer title={"Powiadomienia"}>
      <TableContainer>
        <div>
          <Table>
            <thead>
              <TableHeaderRow>
                <TableHeader>Moduł</TableHeader>
                <TableHeader>Treść komunikatu</TableHeader>
                <TableHeader>
                  <div className="flex items-center justify-center gap-2 relative">
                    <span>Status</span>
                    <button
                      type="button"
                      className="hover:opacity-75"
                      onClick={() => setIsOpen(!isOpen)}
                    >
                      <SortIcon style={{ width: "22px", height: "22px" }} />
                    </button>

                    {isOpen && (
                      <ModalStyle
                        className="absolute mt-40 z-50"
                        style={{
                          border: "2px solid #323a44",
                          borderRadius: "2px",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "#222c38",
                            transition: "background-color 0.2s ease",
                            padding: "3px",
                            width: "100%",
                          }}
                        >
                          {viewLabels.map((label, idx) => (
                            <button
                              key={label + idx}
                              onClick={() => {
                                setVisibility(label);
                                setItems([]);
                                setNextPage(1);
                                reset();
                              }}
                              className="hover:opacity-75"
                              style={{
                                color: "white",
                                height: "38px",
                                padding: "7px 2px",
                                width: "100%",
                                backgroundColor:
                                  label === visibility
                                    ? "rgba(163, 158, 157, 0.38)"
                                    : "#222c38",
                                pointerEvents:
                                  label === visibility ? "none" : "auto",
                                transition: "background-color 0.3s ease",
                                borderRadius:
                                  label === visibility ? "2px" : "0px",
                              }}
                              disabled={label === visibility}
                            >
                              {label}
                            </button>
                          ))}
                        </div>
                      </ModalStyle>
                    )}
                  </div>
                </TableHeader>
                <TableHeader>Data</TableHeader>
                <TableHeader>Edycja</TableHeader>
              </TableHeaderRow>
            </thead>

            <tbody ref={containerRef}>
              {item.length > 0 &&
                item.map((row: Notification | undefined, index: number) => {
                  if (!row) return null;

                  return (
                    <TableRow
                      key={`${row.NotificationId}-${row.SentAt}-${index}`}
                      onClick={() => redirectUserTo(row)}
                      style={{
                        opacity:
                          displayStatus(
                            row.IsSee || false,
                            row.IsComplete || false
                          ) === "Przeczytana"
                            ? "0.2"
                            : "",
                        minHeight: "200px",
                      }}
                      ref={
                        index === notifications.length - 1 ? lastItemRef : null
                      }
                    >
                      <TableCell>
                        <span>{moduleName(row.Type)}</span>
                      </TableCell>
                      <TableCell>
                        <span>{row.Description}</span>
                      </TableCell>
                      <TableCell>
                        <span>
                          {displayStatus(
                            row.IsSee || false,
                            row.IsComplete || false
                          )}
                        </span>
                      </TableCell>
                      <TableCell>
                        <span>
                          {getFormatedDate(new Date(row.SentAt), "date")}
                        </span>
                      </TableCell>
                      <TableCell onClick={(event) => event.stopPropagation()}>
                        <span>
                          <DeleteButton
                            variant={ButtonVariant.Abort}
                            onClick={(event) =>
                              handleNotificationDelete(
                                row.NotificationId,
                                event
                              )
                            }
                          />
                        </span>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </tbody>
          </Table>
          {loading && <LoaderRing text="Pobieranie powiadomień..." />}
        </div>
      </TableContainer>
    </ContentContainer>
  );
};

export default NotificationCenter;

const TableContainer = styled.div`
  width: 100%;
  overflow-x: auto;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0px 8px;
`;

const TableHeader = styled.th`
  position: relative;
  z-index: 30;
  background: rgba(2, 2, 15, 0.5);
  padding: 9px 12px;
  text-transform: uppercase;
  font-size: 11px;
  line-height: 15px;
  height: 48px;
  font-size: 11px;
  line-height: 15px;
  color: white;
  font-family: Inter;
  font-weight: 500;
  border-right: 4px solid #02020f;
  padding: 8px;

  &:nth-child(1) {
    max-width: 20%;
    width: 100%;
  }

  &:nth-child(3) {
    max-width: 15%;
    width: 100%;
  }

  &:nth-child(4) {
    max-width: 10%;
    width: 100%;
  }

  &:last-child {
    border-right: 1px solid transparent;
    max-width: 15%;
    width: 100%;
  }

  @media (max-width: 1024px) {
    &:nth-child(1) {
      padding-left: 10px !important;
    }

    &:nth-child(2) {
      padding-left: 10px !important;
    }
  }
`;

const TableHeaderRow = styled.tr`
  text-align: left;

  > th:nth-child(1) {
    padding-left: 40px;
  }

  > th:nth-child(2) {
    width: auto;
    padding-left: 40px;
    padding-top: 15px;
  }

  > th:nth-child(3) {
    text-align: center;
  }

  > th:nth-child(4) {
    text-align: center;
  }
  > th:nth-child(5) {
    text-align: center;
  }
`;

const TableRow = styled.tr`
  &:hover {
    background: #a39e9d0d;
    cursor: pointer;
  }

  &:nth-child(even) {
    background: rgba(163, 158, 157, 0.08);
  }

  &:hover {
    opacity: 0.5;
    cursor: pointer;
  }
`;

const TableCell = styled.td`
  border-right: 4px solid #02020f;
  padding: 1px;
  height: 48px;
  color: white;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0.375;

  > span {
    background-color: rgba(163, 158, 157, 0.09);
    height: -webkit-fill-available;
    // min-height: 46px !important;
    margin-top: 4px;
    overflow-x: auto;
    opacity: 0.7 !important;
    color: white;
    font-size: 14px;
    font-weight: 300;
    display: flex;
    align-items: center;
    overflow-wrap: break-word;
  }

  &:first-child > span {
    padding-left: 40px;
  }

  &:nth-child(2) {
    max-width: 700px;
  }

  &:nth-child(3) {
    max-width: 700px;
  }

  &:last-child {
    border-right: 1px solid transparent;
  }

  &:nth-child(2) > span {
    padding-left: 40px;
    overflow-y: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    font-size: 12px;
  }

  &:nth-child(3) > span {
    justify-content: center;
  }

  &:nth-child(4) > span {
    justify-content: center;
  }

  &:last-child > span {
    border-right: 1px solid transparent;
    padding: 0 25%;
    overflow-x: hidden;
  }

  @media (max-width: 1024px) {
    > span {
      width: 100%;
      padding: 0;
      justify-content: center;
      display: flex;
      align-items: center;
    }

    &:first-child > span {
      padding-left: 0;
      text-align: center;
      height: -webkit-fill-available;
    }

    &:nth-child(2) > span {
      padding: 0 2%;

      height: -webkit-fill-available;
    }

    &:nth-child(3) > span {
      padding: 0;

      height: -webkit-fill-available;
      font-size: 12px;
    }

    &:nth-child(4) > span {
      padding: 0;

      height: -webkit-fill-available;
      font-size: 8px;
    }

    &:nth-child(5) > span {
      height: -webkit-fill-available;
    }
  }
`;

const ModalStyle = styled.div`

  font-size: 14px;
  width: 100%,
  z-index: 9999,
  color: red,

  @media(min-width: 769px) and (max-width: 1024px) {
    font-size: 10px;
  }

  @media(max-width: 768px) {
    font-size: 9px;
    width: 130%;
  }
`;
